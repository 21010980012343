import axios from 'axios';

export const createMessage = async (token: string, message: string) => {
  const res = await axios.post(
    `${process.env.REACT_APP_API_URL}/messages`,
    { content: message },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
};

export const getMessages = async (token: string, pid: string) => {
  const res = await axios.get(
    `${process.env.REACT_APP_API_URL}/messages/${pid}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return res.data;
};
