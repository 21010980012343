import { useEffect, useState } from 'react';

const useScreenOverlay = (data: any, sendMessageLoading: boolean) => {
  const [canSendMessage, setCanSendMessage] = useState(false);
  const [botName, setBotName] = useState('');
  const [isFirstMessage, setIsFirstMessage] = useState(true);

  useEffect(() => {
    if (!data?.items?.length) {
      return;
    }

    if (data?.items?.filter((el: any) => el.content === 'CTL_BYE').length) {
      setCanSendMessage(false);
      return;
    }

    const filteredData = data.items.filter(
      (el: any) => !el.content.startsWith('CTL_')
    );

    let numOfBotMessages = 0;
    for (let i: number = filteredData.length - 1; i >= 0; i--) {
      if (filteredData[i]?.bot) {
        numOfBotMessages += 1;
      } else {
        break;
      }
    }

    const lastMessage = filteredData[filteredData.length - 1];

    if (!botName) {
      if (lastMessage?.bot_name === 'mona') {
        setBotName('Mona');
      } else if (lastMessage?.bot_name === 'benci') {
        setBotName('Ginevra');
      } else if (lastMessage?.bot_name === 'ermine') {
        setBotName('The Lady');
      }
    }
    if (filteredData.length === 1) {
      const to = setTimeout(() => {
        setIsFirstMessage(false);
        setCanSendMessage(true);
      }, 2000);
      return () => clearTimeout(to);
    }

    if (numOfBotMessages > 0 && filteredData?.length > 1) {
      const to = setTimeout(
        () => setCanSendMessage(true),
        numOfBotMessages * 2500
      );
      return () => clearTimeout(to);
    }
  }, [data, botName, isFirstMessage]);

  return { canSendMessage, setCanSendMessage, botName, isFirstMessage };
};

export default useScreenOverlay;
