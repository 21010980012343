import axios from 'axios';

export const createUser = async (projectionId: string, qrcodeId: string) => {
  const res = await axios.post(
    `${process.env.REACT_APP_API_URL}/users`,
    {
      projection_id: projectionId,
      qrcode_id: qrcodeId,
    },
    {
      headers: {
        Authorization: `Bearer ${process.env.REACT_APP_SECRET}`,
      },
    }
  );
  return res.data;
};

export const getUser = async (token: string) => {
  const res = await axios.get(`${process.env.REACT_APP_API_URL}/users`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return res.data;
};
