import React from 'react';
import { Center, Text } from '@chakra-ui/react';

const NotAllowed = () => {
  return (
    <Center h={'100vh'} p={8}>
      <Text color={'gray.text'} textAlign={'center'} fontSize={'4xl'}>
        Thank you. <br />
        Your chat has ended.
        <br />
        <br />
        Enjoy the exhibition and we hope to see you soon.
      </Text>
    </Center>
  );
};

export default NotAllowed;
