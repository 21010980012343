import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

const queryClient = new QueryClient();

Sentry.init({
  dsn: 'https://dde4459b68324c8395debc1c1c982c45@o1116290.ingest.sentry.io/6166699',
  integrations: [new Integrations.BrowserTracing()],
  environment: process.env.NODE_ENV,
  tracesSampleRate: 0.1,
});

const theme = extendTheme({
  colors: {
    gray: {
      bg: '#282828',
      text: '#e2e2e2',
      dark: '#595959',
    },
    yellow: {
      timer: '#ead33d',
    },
  },
  styles: {
    global: {
      'html, body': {
        background: 'gray.bg',
        height: '100%',
        width: '100%',
        overflow: 'hidden',
      },
      'textarea::placeholder': {
        color: 'gray.dark',
      },
    },
  },
});

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <ChakraProvider theme={theme}>
          <App />
        </ChakraProvider>
      </BrowserRouter>
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
