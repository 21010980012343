import React from 'react';
import { Center, Text } from '@chakra-ui/react';

const NotAllowed = () => {
  return (
    <Center h={'100vh'} p={8}>
      <Text color={'gray.text'} textAlign={'center'} fontSize={'4xl'}>
        Hi there, <br />
        I’m chatting with someone else right now.
        <br />
        <br />
        Please come back in a bit and try again!
      </Text>
    </Center>
  );
};

export default NotAllowed;
