import React, { useEffect, useMemo } from 'react';
import { User } from '../App';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { useMutation } from 'react-query';
import { createUser } from '../api/users';
import * as Sentry from '@sentry/react';

const useInit = (
  user: User | null,
  setUser: React.Dispatch<React.SetStateAction<User | null>> | null
) => {
  const navigate = useNavigate();

  const [params] = useSearchParams();
  const { pathname } = useLocation();

  const { cid, pid } = useMemo(
    () => ({
      cid: params.get('cid') || '',
      pid: params.get('pid') || '',
    }),
    [params]
  );

  const { mutate } = useMutation(
    () => {
      return createUser(pid, cid);
    },
    {
      onSuccess: ({ id, token }) => {
        Sentry.captureMessage('could create user');
        setUser && setUser({ id, token, cid, pid });
        try {
          localStorage.setItem('geniusToken', token);
          navigate('/messages');
        } catch {
          navigate('/messages');
        }
      },
      onError: (error) => {
        Sentry.captureException(error);
        Sentry.captureMessage('could not create user');
        navigate('/try-again');
      },
      retry: 0,
    }
  );

  useEffect(() => {
    if (cid && pid && !user) {
      mutate();
      return;
    }
    if (pathname === '/' && !user) {
      window.location.href = 'https://geniusdavinci.com/';
    }
  }, [mutate, cid, pid, pathname, user]);
};

export default useInit;
