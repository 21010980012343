import React, { createContext, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import Messages from './components/Messages';
import NotAllowed from './components/NotAllowed';
import useInit from './hooks/useInit';
import TryAgain from './components/TryAgain';

export type User = {
  token: string;
  id: string;
  pid: string;
  cid: string;
};

export const AppState = createContext<{
  user: User | null;
  setUser: React.Dispatch<React.SetStateAction<User | null>> | null;
}>({ user: null, setUser: null });

function App() {
  const [user, setUser] = useState<User | null>(null);

  useInit(user, setUser);

  return (
    <AppState.Provider value={{ user, setUser }}>
      <Routes>
        <Route path="/" element={null} />
        <Route path="/messages" element={<Messages />} />
        <Route path="/not-allowed" element={<NotAllowed />} />
        <Route path="/try-again" element={<TryAgain />} />
      </Routes>
    </AppState.Provider>
  );
}

export default App;
