import React from 'react';
import { Center, Fade, Text } from '@chakra-ui/react';

const LookAtScreen = ({
  name,
  isFirstMessage,
  show,
}: {
  name: string;
  isFirstMessage: boolean;
  show: boolean;
}) => {
  return (
    <Fade in={show}>
      <Center
        h={'100vh'}
        w={'100vw'}
        position={'absolute'}
        top={'0'}
        left={'0'}
        p={'9'}
        bg={'black'}
        opacity={'60%'}
        pointerEvents={show ? 'unset' : 'none'}
      >
        <Text color={'gray.text'} textAlign={'center'} fontSize={'4xl'}>
          Look at the big screen. <br />
          {isFirstMessage
            ? `The chat is about to start`
            : `${name} is about to answer`}
        </Text>
      </Center>
    </Fade>
  );
};

export default LookAtScreen;
