import Filter from 'bad-words';
import words from './words';

const filter = new Filter();
filter.addWords(...words);

const profanityFilter = (message: string) => {
  return {
    content: filter.clean(message),
    isProfane: filter.isProfane(message),
  };
};

export default profanityFilter;
