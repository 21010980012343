import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Box, Button, Center, Text, Textarea } from '@chakra-ui/react';
import { useMutation, useQuery } from 'react-query';
import { createMessage, getMessages } from '../api/messages';
import { useNavigate } from 'react-router-dom';
import { AppState } from '../App';
import LookAtScreen from './LookAtScreen';
import useScreenOverlay from '../hooks/useScreenOverlay';
import profanityFilter from '../helpers/profanityFilter/profanityFilter';
import * as Sentry from '@sentry/react';

const TIMEOUT = 30;

const Messages = () => {
  const [message, setMessage] = useState('');
  const [time, setTime] = useState(TIMEOUT);
  const { user, setUser } = useContext(AppState);

  const token = useMemo(() => {
    try {
      const t = localStorage.getItem('geniusToken');
      if (t) {
        return t;
      }
    } catch (err) {
      const t = user?.token;
      if (t) {
        return t;
      }
    }
  }, [user]);

  const handleChange = useCallback((e) => {
    const value = e.target.value;
    if (value.length > 140) {
      return;
    }
    setMessage(value);
  }, []);

  const { mutate, isLoading: sendMessageLoading } = useMutation(
    () => {
      const cleanMessage = profanityFilter(message);
      if (cleanMessage.isProfane) {
        return createMessage(`${token}`, 'CTL_FCK').then((_) =>
          createMessage(`${token}`, cleanMessage.content)
        );
      }
      return createMessage(`${token}`, cleanMessage.content);
    },
    {
      onSuccess: () => {
        setCanSendMessage(false);
        setMessage('');
        setTime(TIMEOUT);
      },
      onError: (err) => {
        Sentry.captureMessage('could not send messages');
        Sentry.captureException(err);
      },
    }
  );

  const { data } = useQuery(
    'messages',
    () => getMessages(`${token}`, user?.pid || ''),
    {
      onError: (error) => {
        reset();
        navigate('/not-allowed');
      },
      enabled: !!user,
      refetchInterval: 2000,
      cacheTime: 0,
      retry: 0,
    }
  );

  const { canSendMessage, setCanSendMessage, botName, isFirstMessage } =
    useScreenOverlay(data, sendMessageLoading);

  const navigate = useNavigate();

  const reset = useCallback(() => {
    setUser && setUser(null);
    try {
      localStorage.removeItem('geniusToken');
    } catch {}
  }, [setUser]);

  useEffect(() => {
    if (!user) {
      navigate('/not-allowed');
    }
  }, [user, navigate]);

  useEffect(() => {
    if (time === 0) {
      reset();
    }
  }, [time, reset]);

  useEffect(() => {
    if (!canSendMessage) {
      return;
    }
    const interval = setInterval(() => {
      setTime((prev) => {
        if (prev === 0) {
          return prev;
        }
        return prev - 1;
      });
    }, 1000);
    return () => clearInterval(interval);
  }, [canSendMessage]);

  return (
    <Box m={5}>
      <Text fontSize={'lg'} color={'gray.text'} mb={5}>
        Hi there, <br />
        you got{' '}
        <Text as={'span'} color={'yellow.timer'}>
          {time} seconds
        </Text>{' '}
        left to submit your message.
      </Text>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          mutate();
        }}
      >
        <Box position={'relative'} mb={5}>
          <Textarea
            bg={'black'}
            border={'none'}
            placeholder={'Type your message'}
            value={message}
            onChange={handleChange}
            color={'white'}
            p={2}
            variant={'unstyled'}
            size={'lg'}
            minH={'33vh'}
          />
          <Text
            position={'absolute'}
            bottom={'5px'}
            right={'5px'}
            color={'gray.text'}
          >
            {140 - message.length} characters left
          </Text>
        </Box>
        <Center>
          <Button
            type="submit"
            color={'white'}
            bgGradient={'linear(to-t, #3a3ed8, #3d64ea)'}
            borderRadius={'999px'}
            _hover={{ bgGradient: 'linear(to-t, #3a3ed8, #3d64ea)' }}
            _active={{ bgGradient: 'linear(to-b, #3a3ed8, #3d64ea)' }}
            size={'lg'}
            isDisabled={!message.length}
            isLoading={sendMessageLoading}
            border={'none'}
          >
            SEND MESSAGE
          </Button>
        </Center>
      </form>
      <LookAtScreen
        show={!canSendMessage}
        name={botName}
        isFirstMessage={isFirstMessage}
      />
    </Box>
  );
};

export default Messages;
